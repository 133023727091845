<template>
    <div>
        <!-- <el-dialog v-model="showPopup" title="Please unlock wallet" :append-to-body="true" @close="close" :fullscreen="isMobile">
            <div class="btns-wrapper">
                <el-button type="primary" @click="initWebWallet('metamask')">
                    <img class="connect-wallet-button-icon" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png"/>
                    <span @click="initWebWallet('metamask')">Connect</span>
                </el-button>
                <el-button type="primary" @click="initWebWallet('walletConnect')">Wallet Connect</el-button>
            </div>
        </el-dialog> -->

        <el-dialog
            :fullscreen="isMobile"
            v-model="showPopup"
            title="Login"
            :append-to-body="true"
            class="login-popup"
            @close="close"
        >
            <div class="btns-login-wrapper">
                <el-button class="connect-wallet-button">
                    <span @click="initWebWallet('metamask')">
                        MetaMask
                        <span class="whitepaper-span-hover metamask-icon"> </span>
                    </span>
                </el-button>
                <el-button class="connect-wallet-button">
                    <span @click="initWebWallet('walletConnect')">
                        WalletConnect
                        <span class="whitepaper-span walletconnect-icon"> </span>
                    </span>
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import { Web3WalletProviderInstance } from "~/composables/Web3WalletProvider";
    import { ElDialog, ElButton } from "element-plus";
    import type { WalletProviderKind } from "~/composables/LoginManager/TypeDefs";

    export default defineComponent({
        emits: ["success", "close"],
        components: {
            "el-dialog": ElDialog,
            "el-button": ElButton,
        },
        data(): {
            showPopup: boolean;
        } {
            return {
                showPopup: true,
            };
        },
        computed: {
            isMobile(): boolean {
                if (window && window.innerWidth) {
                    return window.innerWidth < 900;
                } else {
                    return false;
                }
            }
        },
        methods: {
            async initWebWallet(kind: WalletProviderKind) {
                try {
                    await Web3WalletProviderInstance.init(kind);
                    this.$emit("success");
                } catch (e: unknown) {
                    this.$emit("close");
                    throw new Error("failed to initilize web3 provider: " + kind);
                }
            },
            close() {
                this.$emit("close");
            }
        },
    });
</script>

<style lang="scss">
    .el-button.connect-wallet-button {
        cursor: pointer;
        outline-offset: 0px;
        color: #fff;
        text-align: center;
        transform-style: preserve-3d;
        background-color: rgba(0, 0, 0, 0);
        border: 2px solid #fff;
        border-radius: 48px;
        outline: 1px #c49a4e;
        padding: 18px 34px;
        font-family: Eczar, sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.111em;
        transition: border-color .3s, transform .3s, color .3s;
        transform: translate(0);
        height: auto;

        padding: 14px 24px;
        font-size: 16px;
        line-height: 1.125em;
        margin-bottom: 16px;

        &:hover {
            color: #c49a4e;
            border-color: #ddcead;
            transform: scale3d(.96, .96, 1.01);
        }
    }

    .walletconnect-icon {
        background-image: url("/img/icons/walletconnect.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: -7px;
        margin-left: 6px;
        line-height: 100%;
        display: inline-block;
        position: static;
        width: 24px;
        height: 24px;
    }

    .metamask-icon {
        background-image: url("/img/icons/metamask.svg");z-index: 3;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: -7px;
        margin-left: 6px;
        line-height: 100%;
        display: inline-block;
        position: static;
        width: 24px;
        height: 24px;
    }


    .connect-wallet-button:hover .metamask-icon,
    .connect-wallet-button:hover .walletconnect-icon {
        filter: invert(54%) sepia(98%) saturate(247%) hue-rotate(0deg) brightness(97%) contrast(88%);
    }

    .btns-login-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

</style>